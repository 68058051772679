/* eslint-disable */

export function formatBancos(dataBancos) {
  let bancos = [];
  let sucursales = {};
  Object.keys(dataBancos).forEach((key) => {
    bancos.push({
      value: key,
      text: dataBancos[key].name,
    });

    sucursales[key] = [];
    let sucursalesArr = Object.values(dataBancos[key].branches).map((s) => {
      return {
        value: s.id.toString(),
        text: s.name,
        banco: key,
      };
    });
    sucursales[key].push(...sucursalesArr);
  });

  return { bancos, sucursales }
}


// En el back se guarda sólo la sucursal, con la función consigo el banco_id
export function getBancoId(sucursales, sucursalId) {
    let bancoId = "";
    Object.keys(sucursales).some((key) => {
    let sucursal = sucursales[key].find((item) => item.value == sucursalId);
    if (sucursal) {
        bancoId = sucursal.banco;
        return true;
    }
    });
    return bancoId;
};