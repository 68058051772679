<template>
  <div class="flex">
    <div :class="[{'group cursor-pointer': !disabled},'overflow-hidden relative']">
      <button
      :disabled="disabled"
        :class="[
          { 'bg-main-blue text-white rounded-l': fileName, rounded: !fileName },
          'py-2 px-2 border bg-white group-hover:bg-main-blue text-black group-hover:text-white cursor-initial font-semibold text-xs inline-flex items-center transition duration-300',
        ]"
      >
        <svg
          fill="currentColor"
          height="18"
          viewBox="0 0 24 24"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
        </svg>
        <span v-if="!fileName" class="ml-2">Subir archivo</span>
        <span v-else class="ml-2 truncate" style="max-width: 6rem">{{
          fileName
        }}</span>
      </button>
      <input
      :disabled="disabled"
        :class="[{'cursor-pointer': !disabled},'absolute block opacity-0 top-0 right-0 h-full']"
        type="file"
        name="comprobante"
        accept="application/pdf"
        @change="uploadImage($event)"
      />
    </div>
    <button
      v-if="fileName"
      type="button"
      class="py-2 px-2 border bg-white rounded-r"
      @click="$emit('previewFile')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="18"
        width="18"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { defineComponent, ref, toRef, watch } from "vue";

export default defineComponent({
  emits: ["uploaded", "previewFile"],
  props: {
    aFile: {
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    let fileName = ref(null);
    let aFile = toRef(props, "aFile");
    const uploadImage = ($event) => {
      fileName.value = $event.target.files[0].name;

      emit("uploaded", $event.target.files[0]);
    };

    watch(
      () => aFile.value,
      (newValue) => {
        fileName.value = newValue.name;
        emit("uploaded", newValue);
      }
    );

    return { uploadImage, fileName };
  },
});
</script>