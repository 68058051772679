<template>
  <input
    type="text"
    class="pl-2 border rounded"
    :value="value"
    :placeholder="placeholder"
    :disabled="disabled"
    required
    @keyup="isNumber()"
    @keypress="isNumber()"
  />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["update:value"],
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isNumber = (event) => {
      event = event ? event : window.event;
      let valor = event.target.value.replace(/[^0-9.]/g, "");
      event.target.value = valor ? valor : 0;
      emit("update:value", parseFloat(event.target.value));

      return true;
    };

    return { isNumber };
  },
});
</script>
