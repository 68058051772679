<template>
  <div class="flex col-span-4">
    <h1 class="font-bold mb-1 flex space-x-1">
      <div class="item" v-if="!readOnly">
        <button-delete @borrar="$emit('borrar')"></button-delete>
      </div>
      <span> {{ titulo }} </span>
    </h1>
  </div>
  <div class="w-full col-span-5 flex items-end flex-wrap gap-x-2 gap-y-2">
    <!-- Moneda -->
    <div class="item w-16" v-if="moduloActivo.includes('moneda')">
      <span class="invisible">.</span>
      <c-select
        :id="'moneda'"
        :options="monedas"
        :selected="moneda"
        :can-search="false"
        :disabled="readOnly"
        @update:selected="$emit('update:moneda', $event)"
      ></c-select>
    </div>

    <div class="item" v-if="moduloActivo.includes('importe')">
      <span>Importe</span>
      <input-number
        :value="importe"
        @update:value="$emit('update:importe', $event)"
        :disabled="readOnly"
        class="w-20"
      ></input-number>
    </div>

    <div class="item" v-if="moduloActivo.includes('numero')">
      <span>Nro Cheque</span>
      <input-number
        :value="numero"
        @update:value="$emit('update:numero', $event)"
        :disabled="readOnly"
      ></input-number>
    </div>

    <div class="item date" v-if="moduloActivo.includes('emision')">
      <span>Fecha emisión</span>
      <datepicker
        :value="emision"
        @update:value="$emit('update:emision', $event)"
        :disabled="readOnly"
      >
      </datepicker>
    </div>

    <div class="item date" v-if="moduloActivo.includes('pago')">
      <span>Fecha pago</span>
      <datepicker
        :value="pago"
        @update:value="$emit('update:pago', $event)"
        :disabled="readOnly"
      >
      </datepicker>
    </div>

    <div class="item" v-if="moduloActivo.includes('agencia')">
      <span>Agencias</span>
      <c-select
        :id="'agencias'"
        :options="agencias"
        :selected="agencia"
        :disabled="readOnly"
        @update:selected="$emit('update:agencia', $event)"
      ></c-select>
    </div>

    <div class="item" v-if="moduloActivo.includes('banco')">
      <span>Banco</span>
      <c-select
        :id="'banco'"
        :options="bancos"
        :selected="banco"
        :disabled="readOnly"
        @update:selected="changeBanco"
      ></c-select>
    </div>

    <!-- Sucursal oculta en Giro con primera opción preseleccionada por pedido de back -->
    <div :class="[{ hidden: tipo === 'giro' }]">
      <div
        class="item"
        v-if="moduloActivo.includes('sucursal') || tipo === 'giro'"
      >
        <span>Sucursal</span>
        <c-select
          :id="'sucursal'"
          :options="opcionesSucursal"
          :selected="sucursal"
          :disabled="readOnly"
          @update:selected="$emit('update:sucursal', $event)"
        ></c-select>
      </div>
    </div>

    <div class="flex" v-if="moduloActivo.includes('file')">
      <div class="item">
        <!-- Descarga de comprobantes -->
        <a id="descargaComprobante" href="" target="_blank" hidden></a>
        <upload-file
          :aFile="aFile"
          :disabled="readOnly"
          @uploaded="$emit('update:file', $event)"
          @previewFile="previewFile(true)"
        ></upload-file>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, toRef, watch } from "vue";
import axios from "axios";
import InputNumber from "./InputNumber.vue";
import CSelect from "./Select.vue";
import Datepicker from "./Datepicker.vue";
import UploadFile from "./UploadFile.vue";
import ButtonDelete from "./PanelAvisosFormBorrar.vue";
import { apiGetComprobantePago } from "@/router/endpoints";

export default defineComponent({
  components: {
    InputNumber,
    Datepicker,
    CSelect,
    UploadFile,
    ButtonDelete,
  },
  props: {
    tipo: {
      type: String,
      required: true,
    },
    titulo: {
      type: String,
      required: false,
    },
    moneda: {
      type: String,
      required: false,
    },
    importe: {
      type: Number,
      required: false,
    },
    numero: {
      type: Number,
      required: false,
    },
    emision: {
      required: false,
    },
    pago: {
      required: false,
    },
    agencia: {
      type: String,
      required: false,
    },
    banco: {
      type: String,
      required: false,
    },
    sucursal: {
      type: String,
      required: false,
    },
    file: {
      required: false,
    },
    bancos: {
      type: Array,
      required: false,
    },
    sucursales: {
      type: Object,
      required: false,
    },
    agencias: {
      type: Array,
      required: false,
    },
    monedas: {
      type: Array,
      required: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    "borrar",
    "update:moneda",
    "update:importe",
    "update:numero",
    "update:emision",
    "update:pago",
    "update:agencia",
    "update:banco",
    "update:sucursal",
    "update:file",
  ],
  setup(props, { emit }) {
    let tipo = toRef(props, "tipo");
    let banco = toRef(props, "banco");
    let sucursales = toRef(props, "sucursales");
    let file = toRef(props, "file");
    let aFile = ref("");

    let opcionesSucursal = computed(() => {
      if (banco.value) {
        return sucursales.value ? sucursales.value[banco.value] : null;
      } else {
        return [];
      }
    });

    // Preselecciona la primera sucursal
    watch(
      () => banco.value,
      () => {
        emit("update:sucursal", sucursales.value[banco.value][0].value);
      }
    );

    let modulos = {
      cheque: [
        "moneda",
        "importe",
        "numero",
        "emision",
        "pago",
        "banco",
        "sucursal",
        "file",
      ],
      giro: ["importe", "moneda", "banco", "file"],
      tarjeta: ["importe", "moneda"],
      efectivo: ["importe", "moneda"],
    };
    const moduloActivo = computed(() => {
      return modulos[tipo.value];
    });

    onMounted(() => {
      if (file.value) {
        previewFile();
      }
    });
    const previewFile = (withDownload) => {
      const link = document.getElementById("descargaComprobante");
      let theFile = file.value;

      if (theFile instanceof File) {
        let objectURL = URL.createObjectURL(theFile);
        link.href = objectURL;
        link.click();
      } else {
        axios
          .get(apiGetComprobantePago(file.value), {
            responseType: "arraybuffer",
          })
          .then((response) => {
            aFile.value = new File([response.data], "Comprobante.pdf", {
              type: "application/pdf",
            });

            if (withDownload) {
              let objectURL = URL.createObjectURL(aFile.value);
              link.href = objectURL;
              link.click();
            }

            emit("update:file", aFile.value);
          });
      }
    };

    const changeBanco = ($event) => {
      emit("update:banco", $event);
      emit("update:sucursal", "");
    };
    return { opcionesSucursal, changeBanco, moduloActivo, previewFile, aFile };
  },
});
</script>
