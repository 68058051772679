
import { defineComponent, ref } from "vue";

export default defineComponent({
  emits: ["borrar"],
  setup(props, { emit }) {
    let modalOpen = ref(false);
    const borrar = () => {
        emit('borrar');
        modalOpen.value = false;
    }
    return { modalOpen, borrar };
  },
});
