/* eslint-disable */

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
export function showToast(text, type) {
  createToast(text, {
    type: type,
    transition: "bounce",
    position: "top-right",
    // hideProgressBar: true,
    showIcon: true,
  });
}

export function checkFormAviso(aviso) {
  if (aviso.pago.length == 0 || aviso.accounts.length == 0) {
    let texto = "";
    if (aviso.pago.length == 0) {
      texto = "Debe seleccionar al menos una forma de pago";
    } else {
      texto = "Debe seleccionar al menos un comprobante a imputar";
    }
    showToast(texto, "warning");
    return false;
  } else {
    return true;
  }
}

export function showError(error) {
  showToast("Ha ocurrido un error, reintente", "danger");
  console.log(error);
}

import { format, parseISO } from "date-fns";
export function formatDataAvisoPago(aviso) {
  // Todos los tipos de pago dentro de un solo array
  const pagos = [];
  const keys = ["cheque", "giro", "tarjeta", "efectivo"];
  keys.forEach((key) => {
    if (aviso[key]) {
      let pago = aviso[key].map((item) => {
        return {
          tipo: item.idTipo,
          importe: item.importe,
          moneda: item.moneda,
          adjunto: item.file,
          nro_cheque: item.numero || null,
          agencia_id: item.agencia || null,
          banco_id: item.sucursal || null,
          fecha_pago: item.pago ? format(item.pago, "yyyy-MM-dd") : null,
          fecha_emision: item.emision
            ? format(item.emision, "yyyy-MM-dd")
            : null,
        };
      });
      pagos.push(...pago);
    }
  });
  pagos.filter((pago) => pago.length > 0); // Limpio vacíos
  let avisoFormateado = {
    client_code: aviso.cliente,
    payment_date: format(aviso.fechaPago, "yyyy-MM-dd"),
    payment_amount: aviso.totalPagado,
    comments: aviso.comentarios,
    status_id: aviso.estado,
    accounts: aviso.comprobantes,
    tipo_cambio: aviso.tipoCambio,
    pago: pagos,
    access_token: aviso.access_token,
  };

  return avisoFormateado;
}

export function objectToFormData(obj, form, namespace) {
  var fd = form || new FormData();
  var formKey;
  for (var property in obj) {
    if (obj[property]) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File) &&
        !(obj[property] instanceof Date)
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
}

import { parse } from "date-fns";
export function stringToDate(date) {
  date = date.split(" ")[0]; //Con esto recorto el hh:ss:mm que llega en algunos casos
  return parse(date, "yyyy-MM-dd", new Date());
}
