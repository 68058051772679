<template>
  <div class="relative">
    <div
      :class="[
        'border rounded p-0.5 cursor-pointer transition duration-300 relative',
        { 'bg-red-400 text-white': modalOpen },
        { 'bg-white text-black hover:bg-red-400 hover:text-white': !modalOpen },
      ]"
      title="Borrar"
      @click="modalOpen = true"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clip-rule="evenodd"
        />
      </svg>
    </div>

    <!-- Tooltip -->
    <div class="absolute top-6 -left-3 z-50" v-if="modalOpen">
      <div
        class="p-2 relative border border-gray-400 rounded bg-white text-black w-40 shadow-xl"
      >
        ¿Está seguro?
        <div class="font-light flex space-x-2 text-gray-500">
          <p class="cursor-pointer hover:text-black" @click="borrar">Confirmar</p>
          <p>/</p>
          <p class="cursor-pointer hover:text-black" @click="modalOpen = false">Cancelar</p>
        </div>
      </div>
      <div
        class="w-16 overflow-hidden inline-block z-50 absolute left-3"
        style="top: -0.95rem"
      >
        <div
          class="h-4 w-4 bg-white border border-gray-400 rotate-45 transform origin-bottom-left"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  emits: ["borrar"],
  setup(props, { emit }) {
    let modalOpen = ref(false);
    const borrar = () => {
        emit('borrar');
        modalOpen.value = false;
    }
    return { modalOpen, borrar };
  },
});
</script>
